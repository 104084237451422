<template>
  <b-row>
    <b-col cols="12">
      <p class="primary fs-24 fw-bold py-2">Certificados</p>
      <p class="text-justify">
        Ten en cuenta que el campo año solo lo necesitas para generar el certificado de declaración de renta
      </p>
    </b-col>
    <b-col cols="12">
      <b-form-row>
        <b-col cols="12" md="4">
          <b-form-group label="Número de obligación">
            <b-form-select v-model="creditoSelected" :state="$v.formC.credito.$dirty ? !$v.formC.credito.$error : null">
              <b-form-select-option :value="null">Seleccione número de obligación</b-form-select-option>
              <b-form-select-option
                :value="credito"
                :key="credito.numeroObligacion"
                v-for="credito in $store.getters.creditos"
              >
                {{ `Nro de Obligación ${credito.numeroObligacion} - ${credito.estado.toUpperCase()}` }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formC.credito) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Tipo de certificado">
            <b-form-select
              :options="tipos"
              @change="calcularPlantilla"
              v-model="$v.formC.tipo.$model"
              :state="$v.formC.tipo.$dirty ? !$v.formC.tipo.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formC.tipo) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Año">
            <b-form-select
              :options="anios"
              :state="stateAnio"
              :disabled="!esAnioRequired"
              v-model="$v.formC[esHistorial ? 'mayorJunio' : 'anio'].$model"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formC.anio) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col cols="12" class="content-end-center">
      <b-button variant="danger" @click="validarTipoCertificado" :disabled="$v.$invalid">
        <i class="las la-search" /> Consultar
      </b-button>
    </b-col>
    <template v-if="archivo !== null">
      <b-col cols="12" class="pt-3">
        <b-form-tags
          size="sm"
          tag-pills
          tag-variant="info"
          limitTagsText="10"
          addButtonText="Agregar"
          :disableAddButton="true"
          placeholder="Agregar correo"
          v-model="correosNotificacion"
          :tag-validator="validarCorreo"
          addButtonVariant="outline-info"
          invalidTagText="Correo invalido"
          duplicateTagText="Correo repetido"
        />
      </b-col>
      <b-col cols="12" class="content-end-center pt-3">
        <b-button variant="danger" @click="enviarNotificacion" :disabled="correosNotificacion.length === 0">
          <i class="las la-paper-plane" /> Enviar
        </b-button>
      </b-col>
    </template>
    <b-col cols="12" lg="10" offset-lg="1" class="content-center my-3">
      <div class="content-center pointer-card-info">
        <div class="d-none d-sm-block">
          <embed v-if="archivo !== null" class="content-pdf" type="application/pdf" :src="archivo" />
          <img v-else src="@/assets/images/anothers/dummieCertificados.png" class="content-dummie" />
        </div>
        <div class="d-block d-sm-none py-4">
          <b-button variant="danger" :disabled="archivo === null" @click="descargarCertificado">Descargar</b-button>
        </div>
      </div>
    </b-col>
    <ModalAceptarCertificado
      @sendForm="aceptarCertificado"
      :datosModal="datosModal"
      @useModal="useModal"
      :modal="modal"
    />
  </b-row>
</template>

<script>
import ReporteriaService from "@/app/core/api/reporteria";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import NotificacionService from "@/app/core/api/notificacion";
import { required, requiredIf } from "vuelidate/lib/validators";
import AlertsService from "@/app/shared/services/alerts.service";

// COMPONENTS
import ModalAceptarCertificado from "../components/ModalAceptarCertificado.vue";

export default {
  components: { ModalAceptarCertificado },
  mixins: [mixinsEvents],
  data() {
    return {
      tipos: [],
      anios: [],
      archivo: null,
      solicitud: null,
      archivoMail: null,
      creditoSelected: null,
      correosNotificacion: [],
      modal: { isOpen: false },
      datosModal: { tipo: null, mensaje: "" },
      formC: {
        tipo: null,
        anio: null,
        credito: null,
        plantilla: null,
        tipCodigo: null,
        mayorJunio: null,
        codigoOrigen: null,
        usuario: this.$store.getters.identificacion,
      },
    };
  },
  watch: {
    creditoSelected() {
      if (this.creditoSelected !== null) {
        this.formC = {
          ...this.formC,
          tipo: null,
          anio: null,
          tipCodigo: this.creditoSelected.tipCodigo,
          credito: this.creditoSelected.numeroObligacion,
          codigoOrigen: this.creditoSelected.codigoOrigen,
        };
        this.calcularTipos();
      } else {
        this.tipos = [];
        this.anios = [];
        this.formC = { ...this.formC, tipo: null, anio: null, credito: null };
      }

      this.$nextTick(() => this.$v.$reset());
    },
    "formC.tipo"() {
      if (!this.esAnioRequired) {
        this.formC.anio = null;
        this.$nextTick(() => this.$v.formC.anio.$reset());
      }
    },
  },
  computed: {
    esCreditoCancelado() {
      return this.creditoSelected.estado?.toUpperCase().startsWith("CANCELADO");
    },
    verificarEdadesCartera() {
      const { edadCartera } = this.creditoSelected;
      return edadCartera === 4 || edadCartera === 5 || edadCartera === 7;
    },
    esCreditoMora() {
      return this.creditoSelected.diasEnMora > 0;
    },
    esAnioRequired() {
      const tipo = this.formC.tipo;
      if (tipo === "ESTADOCR" || tipo === "CERTIDIA" || tipo === "PAZYSALVO") return false;
      return true;
    },
    esHistorial() {
      return this.formC.tipo === "HISTORIAL" ? true : false;
    },
    stateAnio() {
      return this.$v.formC[this.esHistorial ? "mayorJunio" : "anio"].$dirty
        ? !this.$v.formC[this.esHistorial ? "mayorJunio" : "anio"].$error
        : null;
    },
  },
  validations: {
    formC: {
      tipo: { required },
      usuario: { required },
      credito: { required },
      anio: {
        required: requiredIf(function () {
          return this.esHistorial ? false : this.esAnioRequired;
        }),
      },
      mayorJunio: {
        required: requiredIf(function () {
          return this.esHistorial;
        }),
      },
    },
  },
  methods: {
    validarTipoCertificado() {
      this.archivo = null;
      this.archivoMail = null;
      this.datosModal = { tipo: null, mensaje: "" };
      this.solicitud = null;

      this.formC.anio = this.formC.anio !== null ? this.formC.anio : 0;

      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.validarAlertas()) return;

      let tipoCertificado = { codigoSolicitud: "", metodoCertificado: "" };

      if (this.formC.tipo === "RENTA") {
        tipoCertificado = { codigoSolicitud: "DRSLF01", metodoCertificado: "crearDeclaracionRenta" };
      }
      if (this.formC.tipo === "HISTORIAL") {
        tipoCertificado = { codigoSolicitud: "HPSLF01", metodoCertificado: "crearHistorialPagos" };
      }

      if (tipoCertificado.metodoCertificado !== "") {
        ReporteriaService[tipoCertificado.metodoCertificado](this.formC).then((response) => {
          const data = response.data;
          if (!data?.estado || !data?.contenidoArchivo) {
            return AlertsService.error("Certificados", "El crédito enviado NO existe.");
          }
          if (data.contenidoArchivo !== tipoCertificado.codigoSolicitud) {
            this.datosModal.mensaje = `con el número de solicitud ${data.contenidoArchivo}`;
            this.solicitud = data.contenidoArchivo;
          }
          this.datosModal.tipo = this.formC.tipo;
          this.useModal();
        });
      } else {
        this.generarCertificado(this.formC);
      }
    },
    aceptarCertificado(tipo) {
      const metodo = tipo === "HISTORIAL" ? "consultarHistorialPagos" : "consultarDeclaracionRenta";

      if (this.solicitud !== null && metodo !== null) {
        ReporteriaService[metodo](this.solicitud).then((response) => {
          if (response.data != null && response.data.archivoBase64 != null) {
            this.archivoMail = response.data.archivoBase64;
            let base64 = Buffer.from(response.data.archivoBase64, "base64");
            let blob = new Blob([base64], { type: "application/pdf", encoding: "utf-8" });
            this.archivo = URL.createObjectURL(blob);
          }
        });
      } else {
        this.generarCertificado();
      }
    },
    generarCertificado() {
      ReporteriaService.generarCertificado(this.formC).then((response) => {
        if (response.data.estado) {
          this.archivoMail = response.data.contenidoArchivo;
          let base64 = Buffer.from(response.data.contenidoArchivo, "base64");
          let blob = new Blob([base64], { type: "application/pdf", encoding: "utf-8" });
          this.archivo = URL.createObjectURL(blob);
          AlertsService.success("Certificados", response.data.mensaje);
        } else {
          this.archivo = null;
          AlertsService.error("Certificados", response.data.mensaje);
        }
      });
    },
    descargarCertificado() {
      let element = document.createElement("a");
      element.setAttribute("href", `data:application/pdf;base64,${this.archivoMail}`);
      element.setAttribute("download", `Certificado ${this.tipos.find((el) => el.value === this.formC.tipo).text}`);
      document.body.appendChild(element);
      element.click();
    },
    useModal() {
      this.modal.isOpen = !this.modal.isOpen;
    },
    calcularTipos() {
      this.tipos = [];

      if (this.formC.codigoOrigen !== null && this.formC.codigoOrigen > 0) {
        this.tipos = [{ value: "HISTORIAL", text: "Historial de Pagos" }];
        const esAnioActual = new Date(this.creditoSelected.fechaDesembolso).getFullYear() === new Date().getFullYear();

        if (!esAnioActual) this.tipos.push({ value: "RENTA", text: "Declaración de Renta" });

        if (this.formC.codigoOrigen < 300) {
          if (this.esCreditoMora) {
            this.tipos.push({ value: "ESTADOCR", text: "Estado de Crédito" });
          } else {
            if (this.esCreditoCancelado) {
              this.tipos.push({ value: "PAZYSALVO", text: "Paz y Salvo" });
            } else {
              this.tipos.push({ value: "ESTADOCR", text: "Estado de Crédito" });
              this.tipos.push({ value: "CERTIDIA", text: "Certificado al Día" });
            }
          }
        }
      }

      if (this.formC.tipCodigo !== null && this.formC.tipCodigo > 0) {
        this.tipos = [{ value: "HISTORIAL", text: "Historial de Pagos" }];
        const esAnioActual = new Date(this.creditoSelected.fechaDesembolso).getFullYear() === new Date().getFullYear();

        if (!esAnioActual) this.tipos.push({ value: "RENTA", text: "Declaración de Renta" });

        if (this.formC.tipCodigo !== 1 && this.formC.tipCodigo !== 2 && this.formC.tipCodigo !== 7) {
          this.tipos.push({ value: "ESTADOCR", text: "Estado de Crédito" });
          if (this.formC.tipCodigo === 6) {
            this.tipos.push({ value: "PAZYSALVO", text: "Paz y Salvo" });
          }
        } else {
          if (this.esCreditoCancelado) {
            if (this.formC.tipCodigo == 1 || this.formC.tipCodigo == 2 || this.formC.tipCodigo == 6) {
              this.tipos.push({ value: "PAZYSALVO", text: "Paz y Salvo" });
            }
          } else {
            this.tipos.push({ value: "ESTADOCR", text: "Estado de Crédito" });
            this.tipos.push({ value: "CERTIDIA", text: "Certificado al Día" });
          }
        }
      }
    },
    calcularAnios(value) {
      this.anios = [];
      if (value == "HISTORIAL") {
        this.anios.push({ value: false, text: "Antes de 01/05/2023" });
        this.anios.push({ value: true, text: "Desde el 01/05/2023" });
      } else {
        const date = new Date(this.creditoSelected.fechaDesembolso);
        for (let index = date.getFullYear(); index <= new Date().getFullYear(); index++) {
          this.anios.push({ text: index, value: index });
        }
      }
    },
    calcularPlantilla(value) {
      this.calcularAnios(value);
      this.formC.plantilla = null;
      if (value == "CERTIDIA") this.formC.plantilla = "CERTDEUD";
      if (value == "ESTADOCR") this.formC.plantilla = "CERESTCU";
      if (value == "PAZYSALVO") this.formC.plantilla = "PAZYSAL";
    },
    enviarNotificacion() {
      const formI = {
        listaConCopia: [],
        valoresReemplazo: [],
        listaConCopiaOculta: [],
        extensionAdjunto: ".pdf",
        base64Adjunto: this.archivoMail,
        anio: this.formC.anio.toString(),
        listaDestinatario: this.correosNotificacion,
        nombreCliente: this.creditoSelected.nombreCompleto,
        nombreInforme: this.$store.getters.tiposNotificacion[this.formC.tipo],
        mes: this.$store.getters.listaMeses.find((item) => item.value == new Date().getMonth() + 1).text,
      };

      NotificacionService.enviarNotificacion(formI).then((response) => {
        AlertsService.success("Certificados", response.data.mensaje);
      });
    },
    validarAlertas() {
      const tipo = this.formC.tipo;
      const estado = this.creditoSelected.estado.toUpperCase();
      const MSG = "comunícate con nuestras Líneas de Servicio al Cliente ProgreSER.";

      if (tipo === "PAZYSALVO") {
        if (estado === "CANCELADO POR RESTRUCTURACIÓN" || estado === "CANCELADO FONDO DE GARANTIAS.") {
          let msg = `Por favor ${MSG}`;
          const mensajeInfo = this.makeAlertArray(msg, this.telefonos);
          AlertsService.error("Certificados", mensajeInfo);
          return true;
        }
      }

      if (tipo === "HISTORIAL" || tipo === "ESTADOCR" || tipo === "RENTA" || tipo === "CERTIDIA") {
        if (this.verificarEdadesCartera) {
          let msg = `Tu crédito se encuentra en una etapa de Mora Avanzada, para brindarte la información solicitada ${MSG}`;
          const mensajeInfo = this.makeAlertArray(msg, this.telefonos);
          AlertsService.error("Certificados", mensajeInfo);
          return true;
        }
      }

      if (tipo === "CERTIDIA" && this.esCreditoMora) {
        let msg = `Por favor ${MSG}`;
        const mensajeInfo = this.makeAlertArray(msg, this.telefonos);
        AlertsService.error("Certificados", mensajeInfo);
        return true;
      }

      return false;
    },
  },
};
</script>
